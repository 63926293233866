import React from 'react'
import { helpers } from '@common'
import styles from './style.m.scss'
import { Translate } from '@pc/config/translate'
const className = helpers.classNames.react(styles)

export const ATMBankTransferGuide = (props) => {
  const translate = Translate.getInstance().translate

  return (
    <section {...className('bankTransfer-guide-content')}>
      <ul {...className('backTransfer-guide-list')}>
        <li>{translate('支付指南，')}</li>
        <li>{translate('选择【付费/购买】菜单，')}</li>
        <li>{translate('选择【其他】，')}</li>
        <li>{translate('选择【多付款】，')}</li>
        <li>{translate('【12345】输入 - 例如，')}</li>
        <li>{translate('正如【机构代码】，')}</li>
        <li>{translate('输入虚拟账号，例如【12345XXXXXXXXXXX】，')}</li>
        <li>{translate('选择【右】，')}</li>
        <li>{translate('选择【是】，')}</li>
        <li>{translate('选择【是】，')}</li>
        <li>{translate('拿你的付款证明，')}</li>
        <li>{translate('完')}</li>
      </ul>
    </section>
  )
}

export const MobileBankTransferGuide = (props) => {
  const translate = Translate.getInstance().translate

  return (
    <section {...className('bankTransfer-guide-content')}>
      <ul {...className('backTransfer-guide-list')}>
        <li>{translate('支付指南，')}</li>
        <li>{translate('登录手机银行，')}</li>
        <li>{translate('选择【支付】，')}</li>
        <li>{translate('选择【多付款】，')}</li>
        <li>{translate('【Transferpay】输入，')}</li>
        <li>{translate('作为【服务提供商】，')}</li>
        <li>{translate('输入虚拟账号，例如【12345XXXXXXXXXXX】，')}</li>
        <li>{translate('选择【继续】，')}</li>
        <li>{translate('输入【OTP】和【PIN】，')}</li>
        <li>{translate('选择【确定】，')}</li>
        <li>{translate('显示付款证明，')}</li>
        <li>{translate('完')}</li>
      </ul>
    </section>
  )
}

export const InternetBankTransferGuide = (props) => {
  const translate = Translate.getInstance().translate

  return (
    <section {...className('bankTransfer-guide-content')}>
      <ul {...className('backTransfer-guide-list')}>
        <li>{translate('支付指南，')}</li>
        <li>{translate('选择【付费/购买】菜单，')}</li>
        <li>{translate('选择【其他】，')}</li>
        <li>{translate('选择【多付款】，')}</li>
        <li>{translate('【12345】输入 - 例如，')}</li>
        <li>{translate('正如【机构代码】，')}</li>
        <li>{translate('输入虚拟账号，例如【12345XXXXXXXXXXX】，')}</li>
        <li>{translate('选择【右】，')}</li>
        <li>{translate('选择【是】，')}</li>
        <li>{translate('选择【是】，')}</li>
        <li>{translate('拿你的付款证明，')}</li>
        <li>{translate('完')}</li>
      </ul>
    </section>
  )
}

export const StoreTransferGuide = (props) => {
  const translate = Translate.getInstance().translate
  return (
    <section>
      <ul
        {...className('store-transfer-guide-list', {
          hide: !props.show,
        })}
      >
        <li>
          {translate(
            '1.记录并保存您的Alfamart/Indomaret付款代码，即：${paymentCode}.',
            {
              paymentCode: props.payCode,
            },
          )}
        </li>
        <li>
          {translate(
            '2.去最近的Alfamart / Indomaret收银台，告诉收银员你想付款“Akulaku”。',
          )}
        </li>
        <li>
          {translate(
            '3.向收银员出示您的Alfamart / Indomaret支付代码，并请支付${amount}.',
            {
              amount: `${props.amount}`,
            },
          )}
        </li>
        <li>{translate('4.将您的付款收据保存,该收据为合法付款凭证。')}</li>
      </ul>
    </section>
  )
}
