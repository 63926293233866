import React, { PureComponent } from 'react'
import Iframe from 'react-iframe'

import Icon from '@pc/components/Icon'
import styles from './style.m.scss'
import { helpers } from '@common'
const className = helpers.classNames.react(styles)

export class MidtransIframe extends PureComponent {
  render() {
    const { midtransUrl, closeIframe, loadingText, platform } = this.props
    const isMobile = platform !== 'pc'

    return (
      <section
        {...className('midtrans-iframe-mask', {
          'midtrans-iframe-mask-pc': !isMobile,
        })}
      >
        <div
          {...className('', {
            'midtrans-iframe-container': isMobile,
            'midtrans-iframe-container-pc': !isMobile,
          })}
        >
          <Icon
            icontype="svg"
            name="dialogClose"
            {...className('midtrans-iframe-closeIcon')}
            onClick={closeIframe}
          />
          {<Iframe url={midtransUrl} {...className('midtrans-iframe')} />}
        </div>
      </section>
    )
  }
}
