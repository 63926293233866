import React, { PureComponent } from 'react'
import Icon from '../Icon'
import styles from './style.m.scss'
import { helpers } from '@common'
const className = helpers.classNames.react(styles)

export class SlideDown extends PureComponent {
  constructor(props) {
    super()
    this.state = {
      activeIndex: props.defaultActiveIndex ? props.defaultActiveIndex : '0',
    }
  }

  onClickTitle = (index) => {
    this.setState({
      activeIndex: index,
    })
  }

  makeSlideDownContent = (list) => {
    return list.map((item, index) => {
      const show = index === +this.state.activeIndex

      return (
        <div {...className('content-wrapper')} key={item.title}>
          <div
            {...className('content-title')}
            onClick={() => this.onClickTitle(index)}
            expanded={show.toString()}
          >
            <span>{item.title}</span>
            <Icon
              {...className('content-title-icon')}
              icontype="svg"
              name="arrow-right-light"
            />
          </div>
          <div {...className('content', { show: show })}>{item.content}</div>
        </div>
      )
    })
  }

  render() {
    const { list } = this.props

    return (
      <div {...className('slide-down-container')}>
        {this.makeSlideDownContent(list)}
      </div>
    )
  }
}
