import React from 'react'
import { Route } from 'react-router-dom'
import { Redirect } from 'react-router'
import { ConnectedRouter } from 'connected-react-router/immutable'

import { asyncComponent } from '@common'
import { Translate } from './translate'

import { history } from '../store'
import Main from '../views/main/index.pc'
// import OrderDetailsPc from '../views/orderDetails/index.pc.js'
import ApplyInstruction from '../views/applylimit-instruction/index.pc.js'
import PaymentCodePc from '../views/paymentMethod/views/PaymentCode/index.pc'
import PaymentMethodPc from '../views/paymentMethod/index.pc'
import Header from '@pc/components/Header'

// const PaymentMethodPc = asyncComponent(() =>
//   import(/* webpackChunkName: 'openPay/PaymentMethodPc' */ '../views/paymentMethod/index.pc')
// )

const LoginPc = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/pc/signin' */ '../views/login/index.pc.js'
  ),
)
const OrderDetailsPc = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/pc/orderDetails' */ '../views/orderDetails/index.pc.js'
  ),
)

const PaymentResultPc = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/PaymentMethodPc' */ '../views/paymentResult/index.pc'
  ),
)

const PaymentContinuePc = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/PaymentContinuePc' */ '../views/pay-continue/index.pc'
  ),
)

const CouponCenterPc = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/CouponCenterPc' */ '../views/coupon-center/index.pc'
  ),
)

const Terms = asyncComponent(() =>
  import(/* webpackChunkName: 'openPay/Terms' */ '../views/terms'),
)

const CreateOrderFailed = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/old/CreateOrderFailed' */ '../views/CreateOrderFailed'
  ),
)

const ApprovalResult = asyncComponent(() =>
  import(
    /* webpackChunkName: 'openPay/approvalResult' */ '../views/approvalResult'
  ),
)

export const AppRouter = () => {
  const translate = Translate.getInstance().translate

  return (
    <ConnectedRouter history={history}>
      <Main>
        <Route
          exact
          path="/openPayPc.html"
          render={() => <Redirect to={`/login${window.location.search}`} />}
        />

        <Route
          path="/pc"
          render={() => <Header text={translate('支付中心')} />}
        />
        <Route
          exact
          path="/pc/login"
          render={() => <Redirect to={`/pc/signin${window.location.search}`} />}
        />
        <Route exact path="/pc/signin" component={LoginPc} />
        <Route exact path="/pc/orderDetails" component={OrderDetailsPc} />
        <Route
          exact
          path="/pc/applylimit-instruction"
          component={ApplyInstruction}
        />
        <Route exact path="/pc/paymentMethod" component={PaymentMethodPc} />
        <Route
          exact
          path="/pc/paymentMethod/paymentCode"
          component={PaymentCodePc}
        />
        <Route path="/pc/paymentResult/" component={PaymentResultPc} />
        <Route exact path="/pc/pay-continue/" component={PaymentContinuePc} />
        <Route exact path="/pc/coupon-center/" component={CouponCenterPc} />
        <Route path="/pc/terms/" component={Terms} />
        <Route path="/pc/CreateOrderFailed" component={CreateOrderFailed} />
        <Route path="/pc/approvalResult" component={ApprovalResult} />
      </Main>
    </ConnectedRouter>
  )
}
