import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal, message } from 'antd'
import { initTracker } from '@pc/common/tracker/tracker'
import { helpers, withBasic } from '@common'
import Logger from '@pc/config/logger'
import { actionCreator } from './store'
// import { Loading } from '@Arui'
import { ModalTitle } from '@pc/components/ModalTitle'
import { history } from '../../store'
import { globalActionCreator } from '@pc/common/globalActions'
import { countryIdMap } from '@pc/common/macro'
import JsCookie from 'js-cookie'
import { initDeviceSDK, getDeviceId } from '@/components/device-sdk'

@withBasic
class Main extends PureComponent {
  render() {
    return [
      <header key="1" />,
      <div className="maxHeight-pc" key="2">
        {this.props.children}
      </div>,
    ]
  }

  handleGlobalTips(prevProps, nextProps) {
    const prevLoading = prevProps.loading.get('show'),
      nextLoading = nextProps.loading.get('show'),
      loadingContent = nextProps.loading.get('content'),
      prevToast = prevProps.toast.get('show'),
      nextToast = nextProps.toast.get('show'),
      prevDialog = prevProps.alertDialog.get('show'),
      nextDialog = nextProps.alertDialog.get('show')

    //控制全局Loading
    if (prevLoading !== nextLoading) {
      if (!nextLoading && this.hide) {
        this.hide()
        this.hide = null
      } else {
        this.hide = message.loading(loadingContent, 0)
      }
    }

    //控制全局Toast
    if (nextToast && prevToast !== nextToast) {
      const { toast, destroyToast } = nextProps
      const content = toast.get('content'),
        duration = toast.get('duration')

      message.info(content, duration, () => destroyToast())
      return
    }

    //控制全局弹窗提示
    if (nextDialog && prevDialog !== nextDialog) {
      const { alertDialog, hideDialog } = nextProps
      const content = alertDialog.get('content')
      const isTextNode = typeof content === 'string'
      const title = alertDialog.get('title')
      const closable = alertDialog.get('closable')
      const dialogContent = isTextNode ? content : content.toJS()
      const footer = alertDialog.get('footer')
        ? alertDialog.get('footer')
        : 'OK'

      this.modalWarning = Modal.warning({
        width: 480,
        centered: true,
        title:
          typeof title === 'string' ? (
            <ModalTitle
              title={title}
              onClick={() => this.hideModalWaring()}
              platform="pc"
              closable={closable}
            />
          ) : (
            title
          ),
        content: dialogContent,
        onOk: () => {
          hideDialog()
          typeof footer !== 'string' &&
            footer.getIn(['0', 'onPress']) &&
            footer.getIn(['0', 'onPress'])()
        },
        okText:
          typeof footer === 'string' ? footer : footer.getIn(['0', 'text']),
      })
    }
  }

  hideModalWaring() {
    const { hideDialog } = this.props

    hideDialog()
    this.modalWarning && this.modalWarning.destroy()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.handleGlobalTips(this.props, nextProps)
  }

  async UNSAFE_componentWillMount() {
    const {
      initBasicOrderInfo,
      getFingerprint,
      // translate,
      languageId,
      countryCode,
      initLogger,
      checkJumpFromOld,
      getDeviceData,
      getGlobalConfig,
    } = this.props

    // 判断是否从老系统通过uid过来的
    getGlobalConfig()
    checkJumpFromOld()
    initBasicOrderInfo(countryCode, languageId)
    initLogger()
    await initDeviceSDK()
    initTracker()
    getFingerprint()
    getDeviceData()
  }
}

const mapStateToProps = (state) => ({
  deviceType: state.getIn(['main', 'deviceType']),
  loading: state.getIn(['main', 'loading']),
  alertDialog: state.getIn(['main', 'alertDialog']),
  toast: state.getIn(['main', 'toast']),
})

const mapDispatchToProps = (dispatch) => ({
  getDeviceData() {
    dispatch(globalActionCreator.globalAsyncGetDeviceData())
  },
  getGlobalConfig() {
    dispatch(globalActionCreator.globalAsyncGetConfig())
  },
  // checkMerchantWhiteList() {
  //   const { appId } = helpers.URL.allParam()
  //
  //   dispatch(actionCreator.checkMerchantWhiteList(appId))
  // },
  initBasicOrderInfo(countryCode, languageId) {
    const { appId, refNo, sign, token, periods } = helpers.URL.allParam()
    const countryId = countryIdMap[countryCode]

    // 此次设置countryId cookie 主要是给安全组件使用的
    JsCookie.set('countryId', countryId)
    // 这里检测到cookie没有languageId才设置默认的languageId
    if (!JsCookie.get('languageId')) {
      languageId &&
        JsCookie.set('languageId', languageId, {
          domain: '.akulaku.com',
        })
    }

    dispatch(
      actionCreator.goInitBasicOrderInfo({
        appId,
        refNo,
        sign,
        token,
        countryId,
        defaultPeriod: periods ? periods : '', //lazada单独的需求
      }),
    )
  },
  async getFingerprint() {
    getDeviceId().then((deviceId) => {
      helpers.storage.set('deviceId', deviceId)
      dispatch(actionCreator.goAddFingerprint(deviceId))
    })
  },
  initLogger() {
    const { appId, refNo, sign } = helpers.URL.allParam()

    const saLogger = Logger.create({
      openPayAppID: appId,
      openPayOrderID: refNo,
      sign,
    })

    // saLogger.onPageView(window.location.pathname)
    history.listen((location) => {
      saLogger.onPageView()
    })
  },
  destroyToast() {
    dispatch(globalActionCreator.toggleToast(false, '', null))
  },
  hideDialog() {
    dispatch(
      globalActionCreator.toggleAlertDialog(false, {
        title: '',
        content: '',
        footer: '',
        closable: undefined,
      }),
    )
  },
  checkJumpFromOld() {
    const { fromOld } = helpers.URL.allParam()
    dispatch(actionCreator.goSetJumpFromOld(fromOld === 'true'))
  },
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main))
