import React, { PureComponent } from 'react'

import Icon from '../Icon'
import { helpers } from '@common'
import styles from './style.m.scss'

const className = helpers.classNames.react(styles)

export default class Header extends PureComponent {
  render() {
    const { text } = this.props

    return (
      <header {...className('al-header')}>
        <div {...className('al-header-inner')}>
          <Icon
            icontype="svg"
            name="akulakuLogo"
            {...className('al-header-logo')}
          />
          <div {...className('al-header-divider')} />
          <p>{text}</p>
        </div>
      </header>
    )
  }
}
