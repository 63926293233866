import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Clipboard from 'clipboard'

import { globalActionCreator } from '@pc/common/globalActions'
import { Layout } from 'antd'

import ContentWrapper from '@pc/components/ContentWrapper'

import { Button } from '@pc/components/Button'
import Logger, * as elementId from '@pc/config/logger'
import loggerConfig from '../../logger.config'
import BizTracker from '@pc/common/tracker/bizTracker'
import { actionCreator } from '../../store'
import { helpers, withBasic } from '@common'
import { DynamicClock } from '../../components/DynamicClock'
import { Translate } from '@pc/config/translate'

import styles from './style.m.scss'
import {
  StoreTransferGuide,
  ATMBankTransferGuide,
  MobileBankTransferGuide,
  InternetBankTransferGuide,
} from '../../components/Guideline'
import { SlideDown } from '@pc/components/SlideDown'
const className = helpers.classNames.react(styles)
const { Content } = Layout
const { get } = helpers

@withBasic
@helpers.hot(module)
class PaymentCodePc extends PureComponent {
  componentDidMount() {
    this.props.enableCopyPaymentCode()
    const saLogger = Logger.getInstance()
    const name = get(this.props, ['location', 'state', 'name'])
    saLogger.onPageView({
      ...loggerConfig.click_paymnet_method,
      Aku_pageStatus: '',
    })
    BizTracker.enter({ sn: 390006 })
  }

  componentWillUnmount() {
    BizTracker.leave()
  }

  render() {
    const { translate, formatPrice, getPaymentResult, currencyCode } =
      this.props

    const {
      payCode,
      expire,
      curPeriod,
      payId,
      amount,
      callbackPageUrl,
      targetName,
      merchantImg,
      popupType,
      nonce,
      sign,
      bizIds,
    } = get(this.props, ['location', 'state'], {})

    return (
      <ContentWrapper {...className('pc-container')}>
        <Content {...className('pc-container-content')}>
          <section {...className('content-left')}>
            <div {...className('top-wrapper')}>
              <div {...className('countdown-wrapper')}>
                <p>{translate('付款倒计时')}</p>
                <DynamicClock type="countdown" endTime={expire} platform="pc" />
              </div>
              <div {...className('payment-icon-wrapper')}>
                <img src={merchantImg} alt={targetName} />
              </div>
            </div>
            <div {...className('down-wrapper')}>
              <div {...className('payment-detail-wrapper')}>
                <div {...className('payment-detail-line')}>
                  <span>{translate('付款码')}</span>
                  <span />
                  <span>{payCode}</span>
                  <span
                    {...className('copy-btn')}
                    id="copyPayCode"
                    data-clipboard-text={payCode}
                  >
                    {translate('复制')}
                  </span>
                </div>
                <div {...className('payment-detail-line')}>
                  {popupType === 'code' ? (
                    <span>
                      {translate('向${store}支付', {
                        store: targetName,
                      })}
                    </span>
                  ) : (
                    <span>
                      {translate('向${bank}转账', {
                        bank: targetName,
                      })}
                    </span>
                  )}
                  <span />
                  <span>
                    {currencyCode} {formatPrice(amount)}
                  </span>
                </div>
                <Button
                  text={translate('查询支付结果')}
                  hollow={true}
                  platform="pc"
                  size={'mid'}
                  {...className('query-result')}
                  onClick={() =>
                    getPaymentResult({
                      curPeriod,
                      amount,
                      callbackPageUrl,
                      payId,
                      nonce,
                      sign,
                      bizIds,
                    })
                  }
                />
                <div {...className('payment-tips')}>
                  <span>
                    {translate(
                      '*请记录页面上的虚拟账户以及支付信息，方便操作转账，最好是能够截屏保存。',
                    )}
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section {...className('content-right')}>
            <h3>
              <span>{translate('转账指引')}</span>
              <span />
            </h3>
            {popupType === 'code' ? (
              <StoreTransferGuide
                show={true}
                payCode={payCode}
                amount={amount}
              />
            ) : (
              <SlideDown
                list={[
                  {
                    title: 'ATM',
                    content: <ATMBankTransferGuide />,
                  },
                  {
                    title: 'MOBILE BANKING',
                    content: <MobileBankTransferGuide />,
                  },
                  {
                    title: 'INTERNET BANKING',
                    content: <InternetBankTransferGuide />,
                  },
                ]}
              />
            )}
          </section>
        </Content>
      </ContentWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  showPaymentGuide: state.getIn([
    'paymentMethod',
    'storeOrBankTransferInfo',
    'showPaymentGuide',
  ]),
})

const mapDispatchToProps = (dispatch) => ({
  getPaymentResult({
    curPeriod,
    price,
    callbackPageUrl,
    payId,
    sign,
    nonce,
    bizIds,
  }) {
    BizTracker.click({ cn: 2 })
    dispatch(
      actionCreator.goGetPaymentResult({
        curPeriod,
        price,
        callbackPageUrl,
        payId,
        isPc: true,
        sign,
        nonce,
        bizIds,
      }),
    )
  },
  togglePaymentGuide(show) {
    dispatch(actionCreator.goTogglePaymentGuide(!show))
  },
  enableCopyPaymentCode(payCode) {
    const clipboard = new Clipboard('#copyPayCode')
    const translate = Translate.getInstance().translate

    clipboard.on('success', (e) => {
      // Toast.info(translate('复制成功'))
      BizTracker.click({ cn: 1 })
      dispatch(globalActionCreator.toggleToast(true, translate('复制成功')))
      e.clearSelection()
    })
  },
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentCodePc),
)
