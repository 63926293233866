import React, { PureComponent } from 'react'
import { Layout } from 'antd'

import { helpers } from '@common'
import styles from './style.m.scss'

const { Content } = Layout
const _className = helpers.classNames.react(styles)

export default class ContentWrapper extends PureComponent {
  render() {
    const { className } = this.props
    return (
      <Layout {..._className(`pc-content ${className}`)}>
        {this.props.children}
      </Layout>
    )
  }
}
