import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Tabs, Layout } from 'antd'
import { withRouter } from 'react-router-dom'

import ContentWrapper from '@pc/components/ContentWrapper'
import withPerfCollect from '@/components/withPerfCollect'

import PaymentCreditCardPc from './components/PaymentCreditCardPc'

// const PaymentCreditCardPc = asyncComponent(() =>
//   import( './components/PaymentCreditCardPc')
// )
import BizTracker from '@pc/common/tracker/bizTracker'
import { actionCreator } from './store'
import { helpers, withBasic } from '@common'

import styles from './style.m.scss'

import './components/CreditCard/style.m.scss'

import { PAY_METHOD_STATUS } from '@pc/common/macro'
import Logger from '@pc/config/logger'
import loggerConfig from './logger.config'
import { globalActionCreator } from '../../common/globalActions'

const className = helpers.classNames.react(styles)
const { TabPane } = Tabs
const { Content } = Layout

@withBasic
class PaymentMethodPc extends PureComponent {
  state = {
    activeIndex: 0,
  }

  choosePaymentMethod = (index, methodItem) => {
    const {
      methodInfo,
      choosePaymentMethodNew,
      location: {
        state: { curPeriod, callbackPageUrl, payId, bizIds },
      },
    } = this.props

    this.setState({
      activeIndex: index,
    })

    choosePaymentMethodNew({
      payId,
      bizIds,
      amount: methodInfo.get('amount'),
      methodId: methodItem.get('payMethod'),
      subMethodId: methodItem.get('subId'),
      merchantImg: methodItem.get('image'),
      validateKeys: methodItem.get('validateKeys'),
      callbackPageUrl,
      curPeriod,
    })

    // doChooseMethodLogic({
    //   methodItem,
    //   curPeriod,
    //   amount: methodInfo.get('amount'),
    //   callbackPageUrl,
    //   id,
    //   type
    // })
  }

  makeMethodListItem = (list) => {
    const { activeIndex } = this.state
    const { countryCode } = this.props

    return list.map((item, index) => {
      //越南/菲律宾隐藏货到付款
      if (countryCode === 'VN' && item.get('payMethod') === 40000) return null
      if (countryCode === 'PH' && item.get('payMethod') === 30000) return null

      // 停用状态不显示支付方式
      if (item.get('status') === PAY_METHOD_STATUS.DISABLED) return null

      return (
        <div
          key={index}
          {...className('paymentMethod-icon-block', {
            active: index === activeIndex,
            // 异常情况下 需要置灰 不可点击
            abnormal: item.get('status') === PAY_METHOD_STATUS.ABNORMAL,
          })}
          onClick={() => this.choosePaymentMethod(index, item)}
          title={item.get('name') + index}
        >
          <img
            {...className('list-img')}
            src={item.get('image')}
            alt={item.get('name')}
          />
        </div>
      )
    })
  }

  renderBar = (props, DefaultBar) => {
    return (
      <div {...className('default-bar-warpper')}>
        <DefaultBar {...props} />
      </div>
    )
  }

  makeMethodListTab = () => {
    const { methodInfo, translate } = this.props
    if (!methodInfo || methodInfo.size < 1) return null

    const methodList = methodInfo.get('list')
    return (
      <Tabs renderTabBar={this.renderBar} tabBarStyle={{ textAlign: 'center' }}>
        {methodList.map((item, index) => {
          const groupName = item.get('groupName')
          return (
            <TabPane
              tab={
                groupName === 'default'
                  ? translate('请选择支付方式')
                  : groupName
              }
              key={groupName + index}
            >
              {groupName !== 'Credit' ? (
                <div {...className('group-title')}>
                  {translate('请选择支付方式')}
                </div>
              ) : (
                <PaymentCreditCardPc
                  subId={item.get('list').get(0).get('subId')}
                  payMethod={item.get('list').get(0).get('payMethod')}
                />
              )}
              <div {...className('paymentMethod-container')}>
                {groupName !== 'Credit' &&
                  this.makeMethodListItem(item.get('list'))}
              </div>
            </TabPane>
          )
        })}
      </Tabs>
    )
  }

  UNSAFE_componentWillMount() {
    loadMidtrans()
  }

  componentDidMount() {
    const { getMethodInfo, location } = this.props
    BizTracker.enter({
      sn: 390005,
    })
    getMethodInfo(location.state)
    this.getAndReportConfig()
  }

  getAndReportConfig = async () => {
    const saLogger = Logger.getInstance()
    const { data } = await this.props.dispatch(
      globalActionCreator.globalGetReportConfig(),
    )
    saLogger.onPageView({
      ...loggerConfig.page_view,
      gatewayMerchantId: data?.gatewayMerchantId,
      gatewayMerchantName: data?.gatewayMerchantName,
      appName: data?.appName,
    })
  }

  componentWillUnmount() {
    BizTracker.leave()
  }

  render() {
    return (
      <ContentWrapper {...className('pc-container')}>
        <Content {...className('pc-container-content')}>
          {this.makeMethodListTab()}
        </Content>
      </ContentWrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  methodInfo: state.getIn(['paymentMethod', 'methodInfo']),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  getMethodInfo(locationState) {
    if (!locationState) return

    const { id, type, bizIds, payId } = locationState

    if ((id && type) || bizIds) {
      dispatch(
        actionCreator.goGetMethodsList(
          bizIds ? { bizIds, payId } : { id, type },
        ),
      )
    }
  },
  doChooseMethodLogic({
    methodItem,
    curPeriod,
    amount,
    callbackPageUrl,
    id,
    type,
  }) {
    dispatch(
      actionCreator.goChoosePaymentMethod({
        methodItem,
        curPeriod,
        amount,
        callbackPageUrl,
        id,
        type,
      }),
    )
  },
  choosePaymentMethodNew({
    payId,
    bizIds,
    amount,
    methodId,
    subMethodId,
    merchantImg,
    validateKeys,
    callbackPageUrl,
    curPeriod,
  }) {
    validateKeys = validateKeys
      ? validateKeys
          .filter((item) => {
            if (item.get('required')) {
              return item.get('key')
            }
          })
          .map((item) => item.get('key'))
          .toJS()
      : null
    dispatch(
      actionCreator.doPaymentCheckout({
        payId,
        bizIds,
        amount,
        methodId,
        subMethodId,
        merchantImg,
        validateKeys,
        callbackPageUrl,
        curPeriod,
      }),
    )
  },
})

const loadMidtrans = () => {
  //@see: https://api-docs.midtrans.com/#get-token
  const script = document.createElement('script')
  script.src = 'https://api.midtrans.com/v2/assets/js/midtrans.min.js'

  document.getElementsByTagName('head')[0].appendChild(script)
}

export default withPerfCollect()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentMethodPc)),
)
