import './antd-reset.scss'

import './style.scss'
import './config/axios.config'

import React from 'react'
import { Provider } from 'react-redux'
import NiceModal from '@ebay/nice-modal-react'
import { Basic, helpers } from '@common'
import { store } from './store'
import { AppRouter } from './config/router.pc'
import { Redirect } from 'react-router-dom'
import { history } from 'umi'

let locale = {
  in: require('./assets/i18n/in.json'),
  en: helpers.i18n.load([() => import('./assets/i18n/en.json')]),
  ms: helpers.i18n.load([() => import('./assets/i18n/ms.json')]),
  vi: helpers.i18n.load([() => import('./assets/i18n/vi.json')]),
}

export default function App() {
  if (window.innerWidth <= 768) {
    return (
      <Redirect
        to={{
          pathname: history.location.pathname.replace('/pc', ''),
          search: history.location.search,
        }}
      ></Redirect>
    )
  }
  return (
    <Basic locale={locale}>
      <Provider store={store}>
        <NiceModal.Provider>
          <AppRouter />
        </NiceModal.Provider>
      </Provider>
    </Basic>
  )
}
