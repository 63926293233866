import React, { PureComponent } from 'react'
import { helpers, withBasic } from '@common'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import BizTracker from '@pc/common/tracker/bizTracker'
import { CreditLimitCard } from './components/CreditLimitCard'
import { CreditInstruction } from './components/CreditInstruction'
import Logger from '@pc/config/logger'
import { actionCreator as orderDetailActionCreator } from '../orderDetails/store'
import { actionCreator } from './store'
import loggerConfig from './logger.config'
import withPerfCollect from '@/components/withPerfCollect'
import styles from './style.m.scss'
import { globalActionCreator } from '@pc/common/globalActions'

const className = helpers.classNames.react(styles)

@withBasic
class ApplylimitInstructionPc extends PureComponent {
  constructor() {
    super()
    this.saLogger = Logger.getInstance()
  }

  componentDidMount() {
    const { saveOrderParam, countryCode } = this.props

    saveOrderParam(null, countryCode)
    this.getAndReportConfig()
    BizTracker.enter({ sn: 390009 })
  }

  componentWillUnmount() {
    BizTracker.leave()
  }

  getAndReportConfig = async () => {
    const { data } = await this.props.dispatch(
      globalActionCreator.globalGetReportConfig(),
    )
    this.saLogger.onPageView({
      ...loggerConfig.page_view,
      refNo: this.props.refNo,
      openPayAppID: this.props.appId,
      gatewayMerchantId: data?.gatewayMerchantId,
      gatewayMerchantName: data?.gatewayMerchantName,
      appName: data?.appName,
    })
  }

  render() {
    const {
      countryCode,
      goApplyLimits,
      goPlaceOrder,
      phoneNumber,
      checkUserCreditStatus,
    } = this.props
    const {
      history: {
        location: { state },
      },
    } = this.props
    const curPeriod = state ? state.curPeriod : '0'
    const callBackPageUrl = state ? state.callBackPageUrl : ''

    return (
      <section {...className('apply-instruction-pc-container')}>
        <CreditLimitCard platform="pc" />
        <CreditInstruction
          platform="pc"
          onApplyClick={() => {
            goApplyLimits(curPeriod, callBackPageUrl, countryCode)
          }}
          onPlaceOrderClick={() => {
            goPlaceOrder(curPeriod, phoneNumber)
          }}
          checkUserCreditStatus={() => checkUserCreditStatus(curPeriod)}
        />
      </section>
    )
  }
}

const mapStateToProps = (state) => ({
  appId: state.getIn(['main', 'appId']),
  refNo: state.getIn(['main', 'refNo']),
  sign: state.getIn(['main', 'sign']),
  phoneNumber: state.getIn(['register', 'phoneNumber']),
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  goApplyLimits(curPeriod, callBackPageUrl, countryCode) {
    BizTracker.click({ cn: 1 })
    if (countryCode === 'PH') {
      window.location.href = '/v2/apply_limit_step1_ph.html?apply_status=1'
    } else {
      // dispatch(actionCreator.toggleRegisterSuccess(false))
      // dispatch(
      //   push({
      //     pathname: './applyLimits/1/1',
      //     state: { curPeriod, callBackPageUrl },
      //     search: window.location.search
      //   })
      // ) //申请授信
      //PC端授信还没写
      window.location.href = '/v2/apply_limit_step1.html?apply_status=1'
    }
  },
  goPlaceOrder(curPeriod, phoneNumber) {
    BizTracker.click({ cn: 2 })
    // dispatch(actionCreator.toggleRegisterSuccess(false))
    dispatch(
      push({
        pathname: './orderDetails',
        state: { curPeriod },
        search: `${window.location.search}&phone=${phoneNumber}`,
      }),
    )
  },
  saveOrderParam(curPeriod, countryCode) {
    dispatch(orderDetailActionCreator.goSaveOrderParam(curPeriod, countryCode))
  },
  checkUserCreditStatus() {
    const saLogger = Logger.getInstance()
    saLogger.onPageClick(loggerConfig.fresh_credit_status)
    dispatch(actionCreator.checkUserCreditStatus())
  },
})

export default withPerfCollect()(
  connect(mapStateToProps, mapDispatchToProps)(ApplylimitInstructionPc),
)
