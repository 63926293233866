import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { CreditCard } from '../CreditCard'
import CreditCardForm from '../CreditCardForm'
import { actionCreator } from '../../store'
import { helpers, withBasic } from '@common'
import { MidtransIframe } from '../MidtransIframe'
import { Button } from '@pc/components/Button'

import styles from './style.m.scss'
const className = helpers.classNames.react(styles)

@withBasic
@helpers.hot(module)
class PaymentCreditCard extends PureComponent {
  render() {
    const {
      hasSubmitted,
      isMaster,
      isVisa,
      cardNmuber,
      expireMonth,
      expireYear,
      cvvNumber,
      midtransUrl,
      closeMidtransIframe,
      translate,
      subId,
      payMethod,
      checkCreditPaymentResult,
      amount,
      location: {
        state: { curPeriod, callbackPageUrl, id },
      },
    } = this.props

    return (
      <div {...className('payment-creditCard-container')}>
        <section {...className('creditCard-area')}>
          {midtransUrl && (
            <MidtransIframe
              midtransUrl={midtransUrl}
              closeIframe={closeMidtransIframe}
              loadingText={translate('加载中，请稍后')}
              platform="pc"
            />
          )}
          <CreditCard
            isVisa={isVisa}
            cardNmuber={cardNmuber}
            expireMonth={expireMonth}
            expireYear={expireYear}
            cvvNumber={cvvNumber}
            platform="pc"
          />
        </section>
        <section {...className('creditCard-form-area')}>
          <CreditCardForm
            platform="pc"
            subId={subId}
            payMethod={payMethod}
            disabled={hasSubmitted}
          />
          <div {...className('creditCard-area-footer')}>
            {!hasSubmitted ? null : (
              <Button
                text={translate('查询支付结果')}
                platform="pc"
                {...className('', {
                  'visa-btn': isVisa,
                  'master-btn': isMaster,
                })}
                onClick={() =>
                  checkCreditPaymentResult(
                    curPeriod,
                    amount,
                    callbackPageUrl,
                    id,
                  )
                }
              />
            )}
          </div>
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  amount: state.getIn(['paymentMethod', 'methodInfo', 'amount']),
  cardNmuber: state.getIn(['paymentMethod', 'creditCardInfo', 'cardNumber']),
  expireMonth: state.getIn(['paymentMethod', 'creditCardInfo', 'expireMonth']),
  expireYear: state.getIn(['paymentMethod', 'creditCardInfo', 'expireYear']),
  cvvNumber: state.getIn(['paymentMethod', 'creditCardInfo', 'cvvNumber']),
  hasSubmitted: state.getIn([
    'paymentMethod',
    'creditCardInfo',
    'hasSubmitted',
  ]),
  isMaster: state.getIn(['paymentMethod', 'isMaster']),
  isVisa: state.getIn(['paymentMethod', 'isVisa']),
  midtransUrl: state.getIn(['paymentMethod', 'midtransUrl']),
})

const mapDispatchToProps = (dispatch) => ({
  changeCardNumber(cardNumber) {
    dispatch(actionCreator.goChangeCardNumber(cardNumber))
  },
  changeExpireYear(year) {
    if (year === '') year = 'YY'

    dispatch(actionCreator.goChangeExpireYear(year))
  },
  changeExpireMonth(month) {
    if (month === '') month = 'MM'

    dispatch(actionCreator.goChangeExpireMonth(month))
  },
  changeCVVnumber(cvv) {
    if (cvv === '') cvv = 'CODE'

    dispatch(actionCreator.goChangeCVVnumber(cvv))
  },
  changeCreditCard(name) {
    let cardObj = {
      isVisa: false,
      isMaster: false,
    }
    Object.keys(cardObj).forEach((key) => {
      if (key === name) {
        cardObj[key] = true
      }
    })
    dispatch(actionCreator.goChangeCreditCard(cardObj))
  },
  payByCreditCard(cardInfo) {
    dispatch(actionCreator.goConnectWithVeritrans(cardInfo))
  },
  closeMidtransIframe() {
    dispatch(actionCreator.removeMidtransUrl())
  },
  checkCreditPaymentResult(curPeriod, price, callbackPageUrl, payId) {
    dispatch(
      actionCreator.goGetPaymentResult(
        curPeriod,
        price,
        callbackPageUrl,
        payId,
        true,
      ),
    )
  },
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentCreditCard),
)
